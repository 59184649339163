// Vendors
import $ from "jquery";
import Vue from 'vue';

// Components
import drawer from './components/drawer';

// Vue vendor modules
import slick from 'vue-slick';
import VueScrollReveal from "vue-scroll-reveal";
import googlemaps from './components/gmap.vue';

import Accordion from './classes/accordion';

class App {

  constructor() {
    window.jQuery = $;
    window.$ = $;

   this.accordion = new Accordion();


    Vue.use(VueScrollReveal, {
      class: 'v-scroll-reveal',
      duration: 800,
      scale: 1,
      distance: '10px',
      mobile: false
    });
  }

  init() {
    new Vue({
      el: '#app',
      delimiters: ['<%', '%>'],
      components: {
        'slick': slick,
        'drawer': drawer,
        'googlemaps': googlemaps,
      },
      data() {
        return {}
      },
      methods: {
        openMenu() {
          if (this.$refs.LeftDrawer.active) {
            this.$refs.LeftDrawer.close();
          } else {
            this.$refs.LeftDrawer.open();
          }
        }
      }
    });
    this.accordion.init();

  }
}

$(() => {
  const app = new App();
  app.init();
});
