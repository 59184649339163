export default class Accordion {
    init(){
        $('.js-accordion-title').click(function(e) {
            e.preventDefault();
          let $this = $(this);
          if ($this.next().hasClass('active')) {
              $this.next().removeClass('active');
              $this.removeClass('active');
              $this.next().slideUp(350);
          } else {
              $this.next().toggleClass('active');
              $this.toggleClass('active');
              $this.next().slideToggle(350);
          }
      });
    }
}